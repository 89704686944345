.result-container {
  padding: 15px 15px 15px 15px;
  width: 700px;
  display: flex;
  justify-content: space-between;
  background-color: #ffe7d9;
  border-radius: 25px;
  margin-top: 16px;
}

.divider {
  flex: 1;
  position: relative;
}

@media screen and (max-width: 992px) and (min-width:500px) {
  .result-container {
    flex-direction: column;
  width: 350px;
  }
}


@media screen and (max-width: 500px) {
    .result-container {
    flex-direction: column;
    width: 200px;
    }
  }
