.h_web {
  height: -webkit-fill-available;
}

.final_result_grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.grid_item_one {
  grid-column: 1 / 2;
}

.grid_item_two {
  grid-column: 2 / 4;
}

.grid_item_three {
  grid-column: 4 / 5;
}

@media (max-width:1024px) {
  .final_result_grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  .grid_item_one {
    grid-column: 1 / 2;
    grid-row: 2/3;
  }

  .grid_item_two {
    grid-column: 1 / 3;
    grid-row: 1/2;
  }

  .grid_item_three {
    grid-column: 2 / 3;
    grid-row: 2/3;
  }
}

@media (max-width:678px) {
  .final_result_grid {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }

  .grid_item_one {
    grid-column: 1 / 2;
    grid-row: 2/3;
  }

  .grid_item_two {
    grid-column: 1 / 2;
    grid-row: 1/2;
  }

  .grid_item_three {
    grid-column: 1 / 2;
    grid-row: 3/4;
  }
}