@media (min-width: 600px) and (max-width: 768px) {
    .noMargin {
        margin-left: 0% !important;
    }
}

@media (min-width: 769px) and (max-width: 900px) {
    .vehicleModel {
        margin-left: 30% !important;
    }
}