@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  font-family: "Nunito";
}

/* --------------Font-Family------------------------------------ */
.font-nunito {
  font-family: "Nunito";
}

.font-Archia {
  font-family: "Archia";
  src: url("./assets/archia-regular-webfont.woff");
}

@font-face {
  font-family: "Archia";
  src: url("./assets/Fonts/Archia-Regular.otf");
}

/* ------------Colour Pallete--------------- */

:root {
  --primary: #ff751f;
  --primaryLight: #ffeee4;
}

/* -----------------Default Text------------------------ */
.default {
  font-size: 18px;
  font-weight: 500;
}

.primary-default {
  font-size: 18px;
  font-weight: 600;
  color: #ff751f;
}

.default-white {
  font-size: 18px;
  font-weight: 600;
  color: white;
}

.font-semib-36 {
  font-size: 36px;
  font-weight: 400;
  color: #ff751f;
  font-family: "Archia";
}

.font-semib-30 {
  font-size: 30px;
  font-weight: 400;
  color: #ff751f;
  font-family: "Archia";
}

.font-nunito-20 {
  font-size: 20px;
  font-weight: 600;
}

.primary-font {
  font-size: 30px;
  font-weight: 400;
  color: black;
  font-family: "Archia";
}

.font-semib-24 {
  font-size: 24px;
  font-weight: 400;
  color: black;
}

.activeButton {
  background-color: var(--primary);
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.inActiveButton {
  color: var(--primary);
  border-color: var(--primary);
  border-width: 2px;
}


@media screen and (max-width: 992px) {
  .font-semib-36 {
    font-size: 20px;
  }
}

@media screen and (min-width:280px) and (max-width:480px) {
  .font-semib-30 {
    font-size: 18px;
  }

  .font-semib-24 {
    font-size: 16px;
  }

  .primary-font {
    font-size: 22px;
  }

  .default {
    font-size: 16px;
  }

  .font-nunito-20 {
    font-size: 16px;
  }

  .primary-default {
    font-size: 16px;
  }

  .default-white {
    font-size: 16px;
  }
}

@media screen and (min-width: 1600px) {
  .container-div {
    padding: 0px 200px 0px 300px;
    background-color: #ffeee4;
    width: 100vw;
  }

  .container-div-white {
    padding: 0px 200px 0px 300px;
    background-color: white;
    width: 100vw;
  }
}

@media screen and (min-width: 1400px) and (max-width:1600px) {
  .container-div {
    padding: 0px 100px 0px 200px;
    background-color: #ffeee4;
    width: 100vw;
  }

  .container-div-white {
    padding: 0px 100px 0px 200px;
    background-color: white;
    width: 100vw;
  }
}

@media screen and (min-width: 992px) and (max-width:1400px) {
  .container-div {
    padding: 0px 50px 0px 100px;
    background-color: #ffeee4;
    width: 100vw;
  }

  .container-div-white {
    padding: 0px 50px 0px 100px;
    background-color: white;
    width: 100vw;
  }
}

@media screen and (min-width:280px) and (max-width:992px) {
  .container-div {
    background-color: #ffeee4;
  }
}

.accordion-button:focus,
.accordion-button:not(.collapsed),
.ovasd,
.accordion-button,
.collapsed {
  border: 1px solid transparent !important;
  background-color: transparent !important;
  box-shadow: transparent !important;
  outline: 1px solid transparent !important;
}

.accordion {
  --bs-accordion-btn-focus-border-color: transparent !important;
  --bs-accordion-btn-focus-box-shadow: transparent !important;
}

.w_250 {
  width: 250px !important;
}

.top_20 {
  top: 20% !important
}

.select_box {
  padding: 2px 0 !important;
}

tr th,
tr td {
  white-space: nowrap;
}

/* .MuiFormControl-root {
  margin-top: 8px !important;
} */

.MuiDialog-root {
  width: 600px !important;
  margin: auto !important;
  z-index: 999 !important;
}

.MuiMenu-paper {
  z-index: 999999 !important;
}

@media (max-width:600px) {
  .MuiDialog-root {
    width: 100% !important;
  }

  .mobile_screen_input {
    top: 125px;
    right: -100px;
    width: 170px;
  }
}

.data_not_found {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.data_not_found h2 {
  font-size: 30px;
  margin-bottom: 10px;
  font-weight: 700;
}

.confirm_modal .MuiBox-root {
  width: 800px !important;
}

@media (max-width: 1000px) {
  .confirm_modal .MuiBox-root {
    width: 500px !important;
  }

  .modal_text {
    font-size: 20px !important;
  }

  .modal_head {
    font-size: 25px !important;
  }
}

@media (max-width: 600px) {
  .confirm_modal .MuiBox-root {
    width: 300px !important;
  }

  .modal_text {
    font-size: 15px !important;
  }

  .modal_head {
    font-size: 20px !important;
    width: 100% !important;
  }
}

.confirm_modal ul li {
  list-style: disc;
  margin-left: 30px;
}

.confirm_modal ul li::marker {
  font-size: 20px;
}
.image_question1 {
    /* width: 500px; */
    height: auto;
    max-height: 800px;
    min-width: 400px;
    max-width: 500px;
    /* object-fit: contain; */
}
.image_question {
  /* width: 500px; */
  height: auto;
  max-height: 800px;
  min-width: 400px;
  max-width: 500px;
  /* object-fit: contain; */
}

@media (max-width:600px) {
  .image_question {
    width: 320px;
    height: 300px;
    /* object-fit: contain; */
  }
}

.MuiChip-label {
  padding: 0 !important;
}

.result_table tr,
.result_table th,
.result_table td {
  border: 2px solid #99ACFF !important;
}

.slide2_table tr th,
.slide2_table tr td {
  font-size: 12px;
  font-weight: 400;
}

.slide2_table tr th {
  color: #fff;
  background: #ff751f;
}

.slide2_table tr:first-child,
.slide2_table tr:last-child {
  background: #ff751f4b !important;
}

.slide2_table tr {
  background: #ff751f2a;
}

.slide2_table tr td {
  background: transparent;
}
@media (min-width: 1000px) {
  .student-row-wise{
    flex-direction: row;
  }
}