.noDataFound {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}

.description-item {
    display: flex;
    column-gap: 5px;
    margin-top: -13px;
}

.description-box {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #111827;
    width: 97%;
    margin-top: 10px;
    flex-wrap: wrap;
    border-radius: 8px;
    justify-content: space-between;
    height: "10px !important";
}

.planExpirationDuration {
    margin-top: 15px !important;
}

.fontLights {
    color: black;
    margin-top: 0px !important;
}

.fields {
    margin-top: "50px !important";
}

.input_upload {
    position: absolute;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
}

