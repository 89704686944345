.audio-info {
    color: #333;
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
    margin: 10px 0;
}

.audio-info span {
    display: block;
    /* margin-bottom: 5px; */
}


.audio-info span:first-child {
    font-weight: bold;
    color: #007bff; /* Change color to blue or your preferred color */
}

.audio-info span:last-child {
    font-style: italic;
}