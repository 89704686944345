.MuiCalendarOrClockPicker-root .MuiPickersCalendarHeader-labelContainer {
    color: black !important;
}

.MuiCalendarOrClockPicker-root .MuiSvgIcon-root {
    color: black !important;
}

.MuiCalendarOrClockPicker-root .MuiPickersDay-root {
    background-color: white !important;
    color: black !important;
}

.MuiPickersDay-root.Mui-selected {
    background-color: rgb(7, 7, 7) !important;
    color: rgb(255, 248, 248) !important;
}

.MuiCalendarOrClockPicker-root .MuiPickersDay-today {
    background-color: rgba(65, 65, 65, 0.629) !important;
    color: rgb(255, 248, 248) !important;
}

.PrivatePickersYear-yearButton {
    color: black !important;
}

.PrivatePickersYear-yearButton.Mui-selected {
    color: rgb(255, 255, 255) !important;
}

.optionContainer>li {
    color: black !important;
}

.optionContainer>li.highlight {
    color: white !important;
}

.searchWrapper {
    min-height: 40px;
    border: 1px solid black;

}

.multiselect-container {
    margin-top: 3px;
}

/* .css-1mbgxj6-MuiFormControl-root-MuiTextField-root {
    width: 181px !important;
} */