html,
body,
#root,
.app,
.content {
  font-family: 'Inter var', Roboto, Helvetica Neue, Arial, sans-serif !important;
}

body {
  margin: 0;
  font-family: 'Inter var', Roboto, Helvetica Neue, Arial, sans-serif !important;
}


.cancel-btn {
  margin-right: 10px !important;
}

.text-end {
  text-align: end !important;
}


::-webkit-scrollbar {
  width: 1px;
  height: 2px;
}



/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fff;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e0e0e0;
}

.css-1rt5bto-MuiTablePagination-selectLabel {
  font-size: 16px !important;
}

.css-17w9904-MuiTypography-root {
  font-size: 15px !important;
}

.ps-menu-button {
  color: rgba(224, 224, 224, .8) !important;
  font-weight: 500 !important;
}

.MuiPaper-root {
  background-color: #fff !important;
}

.ps-menu-button:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-radius: 10px;
  color: #fff !important;
}

.ps-menu-button {
  padding-right: 0px !important;
}

.ps-menuitem-root.ps-active .ps-menu-button {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-radius: 10px;
  color: #fff !important;
}

.recordLimit .MuiSelect-icon {
  fill: "#000 !important";
}

.ps-submenu-content.ps-open.css-z5rm24 {
  background: transparent !important;
}

.ps-submenu-content {
  background-color: #1F2A40 !important;
}

.css-z5rm24 {
  background-color: transparent !important;
}

.ps-collapsed {
  width: 80px !important;
  min-width: 80px !important;
}

.css-16jesut>.ps-menu-button {
  padding-right: 25px !important;
  padding-left: 20px !important;
  font-size: 0.8571428571428571rem;
}

.css-12vkui9>.ps-menu-button {
  padding-right: 0 !important;
  padding-left: 0px !important;
  margin: 0 10px;
  margin-right: 0px;
}

.collapse-menu-view a {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.css-1l5fxv3 {
  width: 220px !important;
}

.css-7ms3qr-MuiTablePagination-displayedRows {
  font-size: 16px !important;
}

.css-jzcd7z-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 16px;
}



.login_img {
  position: relative;
}

.login_img:after {
  position: absolute;
  content: '';
  height: 100%;
  top: 0;
  width: 1px;
  left: 10px;
  background-color: #cac5c5;
  transform: scale(.9);
}

.close-button {
  height: 30px;
  width: 30px;
  position: absolute;
  right: 20px;
  top: 18px;
  cursor: pointer;
  color: #000;
}

.cancel-filter {
  padding: 12px 40px;
  font-size: 15px;
  border: 1px solid rgb(79, 70, 229);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 1);
  background-color: rgb(79, 70, 229);
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
}

.order-filter-button {
  padding: 12px 40px;
  font-size: 15px;
  background: #1F2A40;
  border: 1px solid #1F2A40;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 10px;
  color: white;
  margin-left: 20px;
}


.css-w86noj {
  min-width: 140px !important;

}


.noDataFound {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: BLACK;
  font-size: 20px;
}


button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium.css-1y8qrae-MuiButtonBase-root-MuiIconButton-root {
  color: #000;
}

.css-s66mbf-MuiFormLabel-root-MuiInputLabel-root {
  color: #000;
}

.css-1wjqhm-MuiFormLabel-root-MuiInputLabel-root {
  line-height: 8px !important;
  overflow: visible !important;
}

.css-1coo9st-MuiInputBase-root-MuiOutlinedInput-root {
  height: 40px;
  border: 1px solid;
}

.css-dnkzfa-MuiSwitch-track {
  background-color: #000 !important;
}

label[data-shrink=false]+.MuiInputBase-formControl .css-1xfpnb4-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 5px;
}

.product_detail_img img {
  max-width: 450px;
  width: 100%;
}

.carousel .thumbs-wrapper {
  margin: 0 !important;
  overflow: hidden;
}

.carousel.carousel-slider .control-arrow {
  display: none;
}

.carousel .control-dots {
  bottom: 0;
  display: none;
}

.carousel .carousel-status {
  display: none;
}

.css-171t4xp-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #4f46e5 !important;
}

.css-o8jfh7-MuiTabs-indicator {
  background-color: #4f46e5 !important;
}

.css-eklqwb-MuiTablePagination-selectLabel {
  font-size: 14px !important;
  color: #000 !important;
}

.css-1ug8hdj-MuiTablePagination-displayedRows {
  font-size: 14px !important;
  color: #000 !important;

}

.action-button {
  color: #fff;
  border: 0;
  padding: 10px;
  font-size: 15px;
  border-radius: 5px;
  cursor: pointer;
}

.action-button.view {
  background: #756dff;
}

.default-button {
  color: #000000;
  border: 0;
  padding: 6px 7px 3px 7px;
  font-size: 12px;
  border-radius: 5px;
  cursor: pointer;
}

.edit-button {
  color: #000000;
  border: 0;
  border-color: #000000;
  padding: 6px 7px 3px 7px;
  font-size: 15px;
  border-radius: 5px;
  cursor: pointer;
}

.make-default {
  color: #fff;
  background-color: #676768ee;
  border: 0;
  padding: 6px 7px 3px 7px;
  font-size: 12px;
  border-radius: 5px;
  cursor: pointer;
}

.action-button.edit {
  background: #4caf50;
}

.action-button.delete {
  background: rgb(239, 83, 80);
}
.action-button.revise{
  background: #4ca5af;
}

.action-button.delete-btn {
  background: rgb(239, 83, 80);
}

.css-1ghvzjo-MuiInputBase-root-MuiOutlinedInput-root {
  border: 1px solid #0000003d !important;
}

.css-2y55oh-MuiFormLabel-root-MuiInputLabel-root {
  padding: 3px 3px !important;
}

.css-13xfq8m-MuiTabPanel-root {
  padding: 24px 10px !important;
}

.css-1kk8bgq-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #4f46e5 !important;
}



.css-1esy7ky-MuiFormLabel-root-MuiInputLabel-root {
  -webkit-transform: translate(14px, 16px) scale(1);
  -moz-transform: translate(14px, 16px) scale(1);
  -ms-transform: translate(14px, 16px) scale(1);
  transform: translate(14px, 17px) scale(1) !important;
}

button.add_button {
  height: 40px;
  width: 40px;
  background: rgb(79, 70, 229);
  color: #fff;
  border: 0;
  font-size: 25px;
  border-radius: 7px;
  margin-left: 18px;
}

button.adddelete {
  height: 40px;
  width: 40px;
  background: red;
  color: #fff;
  border: 0;
  font-size: 25px;
  border-radius: 7px;
  margin-left: 18px;
  line-height: 0;
}

.css-w2m9yq-MuiButtonBase-root-MuiButton-root {
  font-weight: 600 !important;
}

.css-q9325e-MuiInputBase-root-MuiTablePagination-select {
  color: #000 !important
}

.css-at26bj-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
  color: #000 !important
}



.css-67d4hh-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: black !important;
}

.css-1mbgxj6-MuiFormControl-root-MuiTextField-root .MuiInputLabel-root.Mui-error {
  color: rgb(92 91 91 / 100%) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.css-1mbgxj6-MuiFormControl-root-MuiTextField-root .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: black !important;
}


@media (max-width:575px) {

  .login_img:after {
    display: none;
  }

}

@media (min-width: 400px) {
  .modelBoxx {
    left: 0px !important;
  }
}

.rdw-editor-main {
  height: "40%"
}

@media (max-width:811px) {
  .MuiGrid-root div[role] {
    right: -10%;
  }
}

@media (max-width:700px) {
  .MuiGrid-root div[role] {
    right: -20%;
  }
}

@media (max-width:600px) {
  .MuiGrid-root div[role] {
    right: -40%;
  }
}

@media (max-width:500px) {
  .MuiGrid-root div[role] {
    right: -60%;
    top: -15px;
    width: 170px !important;
  }

  .MuiGrid-root div[role] .MuiOutlinedInput-input {
    height: 1em !important;
  }
}

@media (max-width:400px) {
  .MuiGrid-root div[role] {
    right: -80%;
  }
}

@media (max-width:370px) {
  .MuiGrid-root div[role] {
    right: -90%;
  }
}